<script>
  import appConfig from "@/app.config";
  import SurveyTeam from "./components/SurveyTeam.vue";
  import SurveyAreas from "./components/SurveyAreas.vue";
import SurveyQuestions from "./components/SurveyQuestions.vue";
import SurveyCharts from './components/SurveyCharts.vue'
import SurveyQuestionsCharts from './components/SurveyQuestionsCharts.vue'
import DistrictsCharts from './components/DistrictsCharts.vue'

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      SurveyTeam,
      SurveyAreas,
      SurveyQuestions,
      SurveyCharts,
      SurveyQuestionsCharts,
      DistrictsCharts
    },
        data() {
          return {
            survey:{},
            surveyChartObject:{}
          }
        },
        methods: {
                getSurvey() {
            this.http.get("marketing-surveys/"+this.$route.params.id).then((res) => {
              this.survey = res.data;
            });
          },
        },
        mounted() {
          this.getSurvey()
        },
  };
</script>

<template>
  <div class="row">
    <div class="row align-items-center">
      <!-- <div>
            <h5 class="mb-4">
              {{ $t("menu.menuitems.marketing.tabs.survey_list") }}
            </h5>
          </div> -->
      <div class="d-flex align-items-center mb-4">
        <router-link :to="`/dashboard/marketing_survey`">
          <h4 class="m-0">
            {{ $t("menu.menuitems.marketing.tabs.survey_list") }}
          </h4>
        </router-link>
        <div class="mx-2">\</div>
        <div> {{ survey && survey.id ? survey.name: $t("menu.menuitems.marketing.survey_details.text") }}</div>
      </div>
    </div>
    <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom">
      <!-- team members -->
      <b-tab :title="$t('menu.menuitems.marketing.survey_details.tabs.team')">
        <survey-team></survey-team>
      </b-tab>
      <b-tab @click="surveyChartObject.refresh()" :title="$t('menu.menuitems.marketing.survey_details.tabs.team_member_charts')">
        
        <SurveyCharts :surveyChartObject="surveyChartObject"/>
      </b-tab>
      <!-- areas -->
      <b-tab :title="$t('menu.menuitems.marketing.survey_details.tabs.districts')">
        <survey-areas></survey-areas>
      </b-tab>
      <!-- questions charts -->
      <b-tab :title="$t('menu.menuitems.marketing.survey_details.tabs.districts_charts')">
        
        <DistrictsCharts/>
      </b-tab>

      <!-- questions -->
      <b-tab :title="$t('menu.menuitems.marketing.survey_details.tabs.questions')">
        <survey-questions></survey-questions>
      </b-tab>
      <!-- questions charts -->
      <b-tab :title="$t('menu.menuitems.marketing.survey_details.tabs.questions_charts')">
        
        <SurveyQuestionsCharts/>
      </b-tab>
    </b-tabs>
  </div>

  <!-- end  table -->

  <!--   Apps  Pagination     -->
  <ul v-if="tot_pages >= 20" class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
        }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
