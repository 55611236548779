export const lineChartData = {
  chartOptions: {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#34c38f"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      align: "left",
      style: {
        fontWeight: "500",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: ["Sat", "Sun", "Mon", "Tue", "Wen", "Thu", "Fri"],
      title: {
        text: "Day",
      },
      tickPlacement: 'between',
    },
    yaxis: {
      title: {
        text: "Interviewers",
      },
      min: 0,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
  series: [
    {
      name: "Interviewers this week",
      data: [26, 24, 32, 36, 33, 31, 33],
    },
  ],
};

export const pieChart = {
  series: [44, 55, 41, 17, 15],
  chartOptions: {
    title: {
      text:"",
      align: "right",
      style: {
        fontWeight: "500",
        color:  '#000000',

      },
    },
    labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
    colors: ["#34c38f", "#556ee6"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
};

export const barChart = {
  series: [{
      data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
  }],
  chartOptions: {
      chart: {
        type: 'bar',

          // toolbar: {
          //     show: false,
          // }
      },
      plotOptions: {
          bar: {
              horizontal: false,
          }
      },
      dataLabels: {
          enabled: false
      },
      colors: ['#34c38f'],
      grid: {
          borderColor: '#f1f1f1',
      },
      xaxis: {
          categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan', 'United States', 'China', 'Germany'],
      }
  }
}