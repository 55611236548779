<template>
  
  <div class="container-fluid my-3">

  <div class="row " style="margin-top: 2rem">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">
            {{ $t("marketing.team_member.line_chart_title") }}
          </h4>
          <chart v-if="chart_data" :type="'line'" :categories="chart_data?.categories" :series="chart_data?.series"
            :title="'Interviews count this week'" x_title="الأيام" y_title="المقابلات" series_title="المقابلات"
            :yaxis_max="chart_data?.yaxis_max" />
        </div>
      </div>
    </div>
  </div>
  </div>


</template>

<script>
import chart from "./chart.vue";

export default {
props:['surveyChartObject'],
data() {
    return {
              chart_data: null,

    }
},
    components:{
        chart
    },
    methods: {
      async  getSurveyStatistics(){

const chart_res = await this.http.post("marketing-survey-processes/survey-interviews-count", { marketing_survey_id: this.$route.params.id }, null, true);
      this.chart_data = chart_res?.data;
        }
    },
    mounted() {
        this.getSurveyStatistics()
        // eslint-disable-next-line vue/no-mutating-props
        this.surveyChartObject.refresh = this.getSurveyStatistics
    },
    

}
</script>

<style>

</style>