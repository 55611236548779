<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      marketing_survey_parts: [
        {
          marketing_survey_id: this.$route.params.id,
          title: "",
          marketing_survey_questions_groups: [
            {
              name: "",
              unit: "",
              alignment: "v",
              multiple_answers: true,
              answer_count: 0,
              marketing_survey_questions: [
                {
                  question: "",
                  marketing_survey_id: this.$route.params.id,
                  marketing_survey_question_options: [
                    {
                      option: "",
                    },
                  ],
                  marketing_survey_question_type: {
                    code: "number",
                  },
                },
              ],
            },
          ],
        },
      ],
      question_types: [],
      filteredQuestions: [],
      allow_edit:false,
      user:{}
    };
  },
  methods: {
    findQuestionById(data, questionId) {
      console.log("step 53 :", data);
      for (let part of data) {
        // Loop through the survey's question groups
        for (let group of part?.marketing_survey_questions_groups) {
          // Loop through the questions in each group
          for (let question of group?.marketing_survey_questions) {
            // Check if the current question's id matches the provided questionId
            if (question?.id == questionId) {
              return question; // Return the matching question object
            }
          }
        }
      }
      return null; // Return null if the question is not found
    },
    searchQuestion(search, connection_type) {
      this.http
        .post("marketing-survey-questions/auto-complete/by-survey-id/search", {
          search,
          connection_type,
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          if (res.status) {
            this.filteredQuestions = res.data;
          }
        });
    },
    showLabel(one) {
      return one.title + " - " + one.name + " - " + one.question;
    },
    removeConditions(q) {
      if (!q.has_conditions) {
        q.connection_type = null;
        q.connection_condition = null;
        q.connection_id = null;
        q.connection_index = null;
        q.connection_to_condition = null;
        q.connection_to_id = null;
        q.connection_to_index = null;
        q.connection_to_type = null;
        this.changeQuestion(q);
        console.log("this is ", q);
      }
    },
    removeIdConditions(q) {
      // q.connection_type = null;
      q.connection_condition = null;
      q.connection_id = null;
      q.connection_index = null;
    },
    removeToConditions(q) {
      if (!q.to_condition) {
        q.connection_to_condition = null;
        q.connection_to_id = null;
        q.connection_to_index = null;
        q.connection_to_type = null;
        this.changeQuestion(q);
        console.log("this is ", q);
      }
    },
    removeIdToCondition(q) {
      q.connection_to_condition = null;
      q.connection_to_id = null;
      q.connection_to_index = null;
    },
    getQuestionsTypes() {
      this.http
        .get("marketing-survey-question-types", null, null, true)
        .then((res) => {
          this.question_types = res.data;
        });
    },
    getMarketingSurveyQuestionBySurveyId() {
      this.http
        .post("marketing-survey-parts/by-survey-id", {
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          if (res.status) {
            this.marketing_survey_parts = res.data;

            console.log("items  64:", this.marketing_survey_parts);
          }
        });
    },
    addPart() {
      console.log("step 33");

      this.http
        .post("marketing-survey-parts", {
          title: "",
          marketing_survey_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.status) {
            this.getMarketingSurveyQuestionBySurveyId();
          }
        });
      // this.marketing_survey_parts.push({
      //   title: "",
      //   marketing_survey_id:this.$route.params.id,

      //   marketing_survey_questions_groups: [
      //     {
      //       name: "",
      //       unit: "",
      //       alignment: "v",
      //       multiple_answers: true,
      //       answer_count: 0,
      //       marketing_survey_questions: [
      //         {
      //                           marketing_survey_id:this.$route.params.id,
      //           question: "",
      //           marketing_survey_question_options: [
      //             {
      //               option: "",
      //             },
      //           ],
      //           marketing_survey_question_type: {
      //             code: "number",
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // });
    },
    deletePart(part) {
      this.popup
        .confirm({
          title: "tree.delete",
          msg: "messages.alert.are_you_sure",
        })
        .then((resp) => {
          console.log(resp);

          if (resp) {
            this.http.delete("marketing-survey-parts", part.id).then((res) => {
              console.log("step 119:", res);

              if (res.status) {
                this.getMarketingSurveyQuestionBySurveyId();
              }
            });
          }
        });
    },
    changeSurveyPartTitle(part) {
      this.http.put("marketing-survey-parts", part.id, part).then((res) => {
        if (res.status) {
          // this.getMarketingSurveyQuestionBySurveyId();
        }
      });
    },
    addGroup(part) {
      this.http
        .post("marketing-survey-questions-groups", {
          marketing_survey_part_id: part.id,
          name: "",
          unit: "",
          multiple_answers: false,
          answer_count: 1,
        })
        .then((res) => {
          if (res.status) {
            this.getMarketingSurveyQuestionBySurveyId();
          }
        });
      // group.push({
      //   name: "",
      //   unit: "",
      //   alignment: "v",
      //   multiple_answers: true,
      //   answer_count: 0,

      //   marketing_survey_questions: [
      //     {
      //                       marketing_survey_id:this.$route.params.id,
      //       question: "",
      //       marketing_survey_question_options: [
      //         {
      //           option: "",
      //         },
      //       ],
      //       marketing_survey_question_type: {
      //         code: "number",
      //       },
      //     },
      //   ],
      // });
    },
    deleteGroup(group) {
      this.popup
        .confirm({
          title: "tree.delete",
          msg: "messages.alert.are_you_sure",
        })
        .then((resp) => {
          console.log(resp);

          if (resp) {
            this.http
              .delete("marketing-survey-questions-groups", group.id)
              .then((res) => {
                console.log("step 119:", res);

                if (res.status) {
                  this.getMarketingSurveyQuestionBySurveyId();
                }
              });
          }
        });
    },
    changeGroup(group) {
      console.log("step 193 :", group);

      this.http
        .put("marketing-survey-questions-groups", group.id, group)
        .then((/* res */) => {
          // if(res.status){
          //   this.getMarketingSurveyQuestionBySurveyId();
          // }
        });
    },
    addQuestion(group,options) {
      this.http
        .post("marketing-survey-questions", {
          fixed_options:group.fixed_options,
          options,
          question: "",
          marketing_survey_question_type_id: this.question_types.find((qt) => qt.code == "radio" )?.id,
          marketing_survey_id: this.$route.params.id,
          marketing_survey_questions_group_id: group.id,
        })
        .then((res) => {
          if (res.status) {
            this.getMarketingSurveyQuestionBySurveyId();
          }
        });
    },
    deleteQuestion(question) {
      this.popup
        .confirm({
          title: "tree.delete",
          msg: "messages.alert.are_you_sure",
        })
        .then((resp) => {
          console.log(resp);

          if (resp) {
            this.http
              .delete("marketing-survey-questions", question.id)
              .then((res) => {
                console.log("step 119:", res);

                if (res.status) {
                  this.getMarketingSurveyQuestionBySurveyId();
                }
              });
          }
        });
    },
    changeQuestion(question, is_select, part_index, group_index,fixed_options) {
      const is_it_fixed_options = fixed_options
      console.log("step 312 :", question);
      console.log(
        "step 313 :",
        this.marketing_survey_parts[part_index]
          ?.marketing_survey_questions_groups[group_index]
          ?.marketing_survey_questions[question?.connection_index]
          ?.marketing_survey_question_type_id !=
          this.question_types.find((qt) => qt.code == "check_box" )?.id
      );

      if (
        question?.connection_type == "values_in_list" &&
        !question?.connection_id /* &&
        this.marketing_survey_parts[part_index]
          ?.marketing_survey_questions_groups[group_index]
          ?.marketing_survey_questions[question?.connection_index]
          ?.marketing_survey_question_type_id !=
          this.question_types.find((qt) => qt.code == "check_box")?.id */
      ) {
        console.log("cond 1",question.connection_condition);

        question.connection_condition =
          question.connection_condition &&
          Array.isArray(question.connection_condition)
            ? JSON.stringify(question.connection_condition)
            : "[]";
      } 
      else if (
        question?.connection_type == "values_in_list" &&
        this.findQuestionById(
          this.marketing_survey_parts,
          question?.connection_id
        )?.marketing_survey_question_type_id ==
          this.question_types.find((qt) => qt.code == "radio")?.id
      ) {
        console.log("cond 3",question.connection_condition);

        question.connection_condition= question.connection_condition && Array.isArray(question.connection_condition) ? JSON.stringify(question.connection_condition):'[]'
      } else if (
        question?.connection_id &&
        this.findQuestionById(
          this.marketing_survey_parts,
          question?.connection_id
        ).marketing_survey_question_type_id ==
          this.question_types.find((qt) => qt.code == "check_box")?.id
      ) {
        console.log(
          "cond 4",
          this.findQuestionById(
            this.marketing_survey_parts,
            question?.connection_id
          )
        );
        question.connection_condition = question.connection_condition ? 1 : 0;
      } else if (Array.isArray(question.connection_condition))
        question.connection_condition = null;

      
      if (
        question?.connection_to_type == "values_in_list" &&
        !question?.connection_to_id &&
        this.marketing_survey_parts[part_index]
          ?.marketing_survey_questions_groups[group_index]
          ?.marketing_survey_questions[question?.connection_index]
          ?.marketing_survey_question_type_id !=
          this.question_types.find((qt) => qt.code == "check_box")?.id
      ) {
        console.log("to cond 2",question.connection_to_condition);
        question.connection_to_condition =
          question.connection_to_condition &&
          Array.isArray(question.connection_to_condition)
            ? JSON.stringify(question.connection_to_condition)
            : "[]";
      } 
      else if (
        question?.connection_to_type == "values_in_list" &&
        this.findQuestionById(
          this.marketing_survey_parts,
          question?.connection_to_id
        )?.marketing_survey_question_type_id ==
          this.question_types.find((qt) => qt.code == "radio")?.id
      ) {
        console.log("to cond 3");

        question.connection_to_condition= question.connection_to_condition && Array.isArray(question.connection_to_condition) ? JSON.stringify(question.connection_to_condition):'[]'
      } else if (
        question?.connection_to_id &&
        this.findQuestionById(
          this.marketing_survey_parts,
          question?.connection_to_id
        ).marketing_survey_question_type_id ==
          this.question_types.find((qt) => qt.code == "check_box")?.id
      ) {
        console.log(
          "to cond 4",
          this.findQuestionById(
            this.marketing_survey_parts,
            question?.connection_to_id
          )
        );
        question.connection_to_condition = question.connection_to_condition ? 1 : 0;
      }
      else if (Array.isArray(question.connection_to_condition))
        question.connection_to_condition = null;
      

      this.http
        .put("marketing-survey-questions", question.id, {...question,fixed_options:is_it_fixed_options})
        .then((res) => {
          if (res.status && is_select) {
            this.getMarketingSurveyQuestionBySurveyId();
            question.has_conditions = Object.assign(
              {},
              question.has_conditions
            );
          }
        });
      console.log("step 284 :", question.connection_condition);
      if (question?.connection_type == "values_in_list")
        question.connection_condition = question.connection_condition
          ? JSON.parse(question.connection_condition)
          : question.connection_condition;
      if (question?.connection_to_type == "values_in_list")
        question.connection_to_condition = question.connection_to_condition
          ? JSON.parse(question.connection_to_condition)
          : question.connection_to_condition;
    },
    addOptions(question) {
      this.http
        .post("marketing-survey-question-options", {
          option: "",
          marketing_survey_question_id: question.id,
        })
        .then((res) => {
          if (res.status) {
            this.getMarketingSurveyQuestionBySurveyId();
          }
        });
    },
    deleteOption(option) {
      this.popup
        .confirm({
          title: "tree.delete",
          msg: "messages.alert.are_you_sure",
        })
        .then((resp) => {
          console.log(resp);

          if (resp) {
            this.http
              .delete("marketing-survey-question-options", option.id)
              .then((res) => {
                console.log("step 119:", res);

                if (res.status) {
                  this.getMarketingSurveyQuestionBySurveyId();
                }
              });
          }
        });
    },
    deleteGroupOption(group,index){
console.log("done :",group);
        group?.options?.splice(index,1)

    },
    changeOption(option, is_select) {
      this.http
        .put("marketing-survey-question-options", option.id, option)
        .then((res) => {
          if (res.status && is_select) {
            this.getMarketingSurveyQuestionBySurveyId();
          }
        });
    },

    submitSurvey() {
      console.log("step 113 :", this.marketing_survey_parts);
      this.http
        .post("marketing-survey-parts", {
          survey_parts: this.marketing_survey_parts,
          marketing_survey_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.status) {
            Swal.fire(this.$t("popups.success"), this.$t("popups.ok")).then(
              () => {}
            );
          }
        });
    },
  },
  created(){
      this.user=JSON.parse(localStorage?.user)

  },
  mounted() {
    this.getMarketingSurveyQuestionBySurveyId();
    this.getQuestionsTypes();
  },
};
</script>

<template>
<div class="row mb-3">
  <div class="col-12">
            <div v-if="!user.roles.some(role=> role?.role?.code =='marketing_survey_manager_no_edit')" class="form-checkbox form-switch mb-3">
                <input class="form-check-input" style="margin-left:unset" role="switch" type="checkbox"   v-model="allow_edit"
                   />
                <label for="exampleInputEmail1" class=" form-check-label mx-2">
                  {{ $t("popups.allow_edit") }}
                </label>

              </div>
  </div>
</div>
  <div class="row mb-3">
    <div class="col-12">
      <h2>{{ $t("marketing.add_question.part_setup") }}</h2>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-start">
        <button
        :disabled="!allow_edit"
          type="button"
          class="btn btn-light float-end"
          @click="addPart()"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
  </div>
  <fieldset
    class="row mb-3"
    v-for="(part, part_index) in marketing_survey_parts"
    :key="part_index"
  >
    <legend>
      {{
        $t("marketing.add_question.question_part") +
        "  " +
        parseInt(part_index + 1)
      }}
    </legend>

    <div class="row justify-content-end">
      <div class="col-4">
        <div class="d-flex justify-content-end">
          <button
            :disabled="!( allow_edit)"
            type="button"
            class="btn float-end"
            @click="deletePart(part)"
          >
            <span class="bx bx-trash text-danger float-end fa-2x"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 mb-5">
      <label class="form-label">{{
        $t("marketing.add_question.part_title")
      }}</label>
      <input
        v-model="part.title"
        @input="changeSurveyPartTitle(part)"
        type="text"
        :disabled="!allow_edit"
        class="form-control"
      />
    </div>
    <div class="col-12 mb-2">
      <legend class="fw-bold">
        {{ $t("marketing.add_question.question_group_settings") }}
      </legend>
    </div>
    <div class="col-4 mb-3">
      <div class="d-flex justify-content-start">
        <button
          type="button"
          :disabled="!allow_edit"
          class="btn btn-light float-end"
          @click="addGroup(part)"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <fieldset
      class="row mb-3 overflow-scroll"
      v-for="(group, group_index) in part.marketing_survey_questions_groups"
      :key="group_index"
    >
      <legend>
        {{
          $t("marketing.add_question.question_group") +
          "   " +
          parseInt(group_index + 1)
        }}
      </legend>

      <div class="row justify-content-end">
        <div class="col-4">
          <div class="d-flex justify-content-end">
            <button
              :disabled=" !( allow_edit)"
              type="button"
              class="btn float-end"
              @click="deleteGroup(group)"
            >
              <span class="bx bx-trash text-danger float-end fa-2x"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <label class="form-label">{{
          $t("marketing.add_question.group_title")
        }}</label>
        <input
          @input="changeGroup(group)"
          v-model="group.name"
            :disabled="!allow_edit"

          type="text"
          class="form-control"
        />
      </div>
<div class="col-md-6 mb-3">
        <div class="row">
          <label class="form-label">{{
            $t("marketing.add_question.fixed_options")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            required
            @change="changeGroup(group)"
            class="form-check-input"
            type="radio"
                      :disabled="!allow_edit"

            v-model="group.fixed_options"
            :value="true"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.yes")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            @change="changeGroup(group)"
            required
                      :disabled="!allow_edit"

            class="form-check-input"
            type="radio"
            v-model="group.fixed_options"
            :value="false"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.no")
          }}</label>
        </div>
      </div>
     

      <div v-if="!group.fixed_options" class="col-md-6 mb-3">
        <div class="row">
          <label class="form-label">{{
            $t("marketing.add_question.multiple_answers")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            required
            @change="changeGroup(group)"
            class="form-check-input"
                      :disabled="!allow_edit"

            type="radio"
            v-model="group.multiple_answers"
            :value="true"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.yes")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            @change="changeGroup(group)"
            required
                      :disabled="!allow_edit"

            class="form-check-input"
            type="radio"
            v-model="group.multiple_answers"
            :value="false"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.no")
          }}</label>
        </div>
      </div>
      
      
      <div
        class="col-md-6 mb-3"
        v-if="
          group?.marketing_survey_questions.some(
            (q) =>
              q.marketing_survey_question_type_id !=
                this.question_types.find((type) => type.code == 'text')?.id &&
              q.marketing_survey_question_type_id !=
                this.question_types.find((type) => type.code == 'number')?.id &&
              q.marketing_survey_question_type_id !=
                this.question_types.find((type) => type.code == 'check_box')
                  ?.id &&
              q.marketing_survey_question_type_id !=
                this.question_types.find((type) => type.code == 'select')?.id
          )&&  !group.fixed_options
        "
      >
        <div class="row">
          <label class="form-label">{{
            $t("marketing.add_question.has_other")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            required
            @change="changeGroup(group)"
            class="form-check-input"
                      :disabled="!allow_edit"

            type="radio"
            v-model="group.has_other"
            :value="true"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.yes")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            @change="changeGroup(group)"
            required
            class="form-check-input"
            type="radio"
                      :disabled="!allow_edit"

            v-model="group.has_other"
            :value="false"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.no")
          }}</label>
        </div>
      </div>
      <div v-if="group.multiple_answers" class="col-md-6 mb-3">
        <label class="form-label">{{
          $t("marketing.add_question.answer_count")
        }}</label>
        <input
          @input="changeGroup(group)"
          required
                    :disabled="!allow_edit"

          v-model="group.answer_count"
          type="number"
          class="form-control"
        />
      </div>
      <div class="col-md-6 mb-5">
        <label class="form-label">{{
          $t("marketing.add_question.unit")
        }}</label>
        <input
          @input="changeGroup(group)"
          v-model="group.unit"
          type="text"
                    :disabled="!allow_edit"

          class="form-control"
        />
      </div>
      <div class="row mb-3">
        <div class="col-12 mb-2">
          <legend class="fw-bold">
            {{ $t("marketing.add_question.question_setup") }}
          </legend>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div v-if="allow_edit" class="d-flex justify-content-start">
            <button
              type="button"
              class="btn float-end"
              :disabled="(group.fixed_options && group?.marketing_survey_questions[0]?.marketing_survey_question_options.length ==0)"
              @click="addQuestion(group,group?.marketing_survey_questions[0]?.marketing_survey_question_options)"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>

            
          </div>
        </div>
      </div>

      <fieldset
        v-for="(question, question_index) in group?.marketing_survey_questions"
        :key="question_index"
        class="row mb-3"
      >
        <legend>
          {{
            $t("marketing.add_question.question") +
            "  " +
            parseInt(question_index + 1)
          }}
        </legend>

        <div class="row mb-3 justify-content-end">
          <div class="col-4">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn float-end"
                @click="deleteQuestion(question)"
                :disabled="!(allow_edit)"
              >
                <span class="bx bx-trash text-danger float-end fa-2x"></span>
              </button>
            </div>
          </div>
        </div>
        <div  v-if="!group.fixed_options" class="col-md-6 mb-3">
          <label for="" class="form-label">
            {{ $t("marketing.add_question.question_type") }}
          </label>
          <select
            required
            @change="changeQuestion(question, true)"
            v-model="question.marketing_survey_question_type_id"
                      :disabled="!allow_edit"

            class="form-select"
            aria-label=".form-select-sm example"
          >
            <option
              v-for="(type, index) in question_types.filter((type) => {
                if (group.multiple_answers) {
                  return (
                    type.code == 'text' ||
                    type.code == 'number' ||
                    type.code == 'select'
                  );
                } else if (group.has_other) {
                  return (
                    type.code == 'text' ||
                    type.code == 'number' ||
                    type.code == 'multiple_choices' ||
                    type.code == 'radio'
                  );
                } else {
                  return type;
                }
              })"
              :value="type.id"
              :key="index"
            >
              {{ $i18n.locale == "en" ? type.name_en : type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label">{{
            $t("marketing.add_question.question")
          }}</label>
          <input
            required
            @input="changeQuestion(question,false,null,null,group.fixed_options)"
            v-model="question.question"
            type="text"
                      :disabled="!allow_edit"

            class="form-control"
          />
        </div>
        <div  v-if="!group.fixed_options" class="col-md-6 mb-3 d-flex align-items-center">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              @change="removeConditions(question)"
              v-model="question.has_conditions"
              type="checkbox"
                        :disabled="!allow_edit"

              role="switch"
              :checked="question?.connection_type"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">{{
              $t("marketing.add_question.has_conditions")
            }}</label>
          </div>
        </div>
        <div
          v-if="
            question?.has_conditions ||
            question?.connection_type ||
            question.connection_id
          "
          class="col-md-6 mb-3 d-flex align-items-center"
        >
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              @change="removeIdConditions(question)"
              v-model="question.has_id_conditions"
              type="checkbox"
              role="switch"
                        :disabled="!allow_edit"

              :checked="question?.connection_id"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">{{
              $t("marketing.add_question.has_group_conditions")
            }}</label>
          </div>
        </div>
        <div
          class="col-md-6 mb-3"
          v-if="question?.connection_type || question?.has_conditions"
        >
          <label class="form-label">{{
            $t("marketing.add_question.connection_type")
          }}</label>
          <select
            @change="
              question.connection_condition == null;
              question.connection_index = null;
              changeQuestion(question, true);
              
            "
            v-if="
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'select' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'check_box'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'number' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'text' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'multiple_choices'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'radio'
              
            "
                      :disabled="!allow_edit"

            v-model="question.connection_type"
            class="form-select"
          >
            <option value="greater_than">
              {{ $t("marketing.add_question.greater_than") }}
            </option>
            <option value="less_than">
              {{ $t("marketing.add_question.less_than") }}
            </option>
            <option value="values_in_list">
              {{ $t("marketing.add_question.values_in_list") }}
            </option>
          </select>
        </div>

        <div
          class="col-md-6 mb-3"
          v-if="
            question?.connection_type &&
            !(question.has_id_conditions || question.connection_id)
          "
        >
          <label class="form-label">{{
            $t("marketing.add_question.connection_index")
          }}</label>
          <select
            @change="changeQuestion(question)"
            v-if="
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'select' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'check_box'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'number' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'text' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'multiple_choices'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'radio'
            "
                      :disabled="!allow_edit"

            v-model="question.connection_index"
            class="form-select"
          >
            <option
              v-for="(
                connection, connection_index
              ) in group?.marketing_survey_questions.filter(
                (inner_question) => inner_question.id != question?.id
              )"
              :key="connection_index"
              :value="connection_index"
              :disabled="
                ((question?.connection_type == 'greater_than' ||
                  question?.connection_type == 'less_than') &&
                  connection.marketing_survey_question_type_id ==
                    question_types.find((type) => type.code == 'number').id) ||
                (question?.connection_type == 'values_in_list' &&
                  connection.marketing_survey_question_type_id ==
                    question_types.find((type) => type.code == 'select').id)
                  ? false
                  : true
              "
            >
              {{ connection.question }}
            </option>
          </select>
        </div>
        <div
          class="col-md-6 mb-3"
          v-if="
            question?.connection_type &&
            (question.has_id_conditions || question.connection_id)
          "
        >
          <label class="form-label">{{
            $t("marketing.add_question.connection_index")
          }}</label>
          <AutoComplete
            :completeOnFocus="true"
            v-model="question.connection"
            @item-select="
              question.connection_id = question.connection.id;
              changeQuestion(question, true);
            "
                      :disabled="!allow_edit"

            :suggestions="
              filteredQuestions.filter((q) => q?.id != question?.id)
            "
            @complete="searchQuestion($event.query, question?.connection_type)"
            :optionLabel="showLabel"
            style="color: #333 !important; border-radius: 10px !important"
          />
        </div>
        <div
          class="col-md-6 mb-3"
          v-if="question?.connection_index || question?.connection_id"
        >
          <label class="form-label">{{
            $t("marketing.add_question.connection_condition")
          }}</label>
          <template
            v-if="
              question?.connection_type == 'greater_than' ||
              question?.connection_type == 'less_than'
            "
          >
            <input
                      :disabled="!allow_edit"

              @input="changeQuestion(question)"
              v-model="question.connection_condition"
              type="number"
              class="form-control"
            />
          </template>

          <template
            v-else-if="
              question?.connection_index ||
              (question?.connection_id &&
                findQuestionById(
                  marketing_survey_parts,
                  question?.connection_id
                )?.marketing_survey_question_type_id ==
                  question_types.find((qt) => qt.code == 'multiple_choices')
                    ?.id)
            "
          >
            <label
              v-for="option in question?.connection_index
                ? group?.marketing_survey_questions[question?.connection_index]
                    ?.marketing_survey_question_options
                : findQuestionById(
                    marketing_survey_parts,
                    question?.connection_id
                  )?.marketing_survey_question_options"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="checkbox"
                v-model="question.connection_condition"
                :value="option.id"
                          :disabled="!allow_edit"

                @change="
                  changeQuestion(question, false, part_index, group_index)
                "
                class="input"
              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option?.option }}
            </label>
          </template>
          <template
            v-else-if="
              question?.connection_id &&
              findQuestionById(marketing_survey_parts, question?.connection_id)
                ?.marketing_survey_question_type_id ==
                question_types.find((qt) => qt.code == 'check_box')?.id
            "
          >
            <label
              v-for="option in [1, 0]"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="radio"
                          :disabled="!allow_edit"

                v-model="question.connection_condition"
                :value="option"
                @change="changeQuestion(question)"
                class="input"
              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option ? $t("popups.yes") : $t("popups.no") }}
            </label>
          </template>
          <template
            v-else-if="
              question?.connection_id &&
              findQuestionById(marketing_survey_parts, question?.connection_id)
                ?.marketing_survey_question_type_id ==
                question_types.find((qt) => qt.code == 'radio')?.id
            "
          >
            <label
              v-for="option in findQuestionById(
                marketing_survey_parts,
                question?.connection_id
              )?.marketing_survey_question_options"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="checkbox"
                          :disabled="!allow_edit"

                v-model="question.connection_condition"
                :value="option.id"
                @change="changeQuestion(question,false, part_index, group_index)"
                class="input"
              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option?.option }}
            </label>
          </template>

          
        </div>

        <div class="row mt-3" v-if="question?.connection_type">
          <div class="col-md-6 mb-3 d-flex align-items-center">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                          :disabled="!allow_edit"

                @change="removeToConditions(question)"
                v-model="question.to_condition"
                type="checkbox"
                role="switch"
                :checked="question?.connection_to_type"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked">{{
                $t("marketing.add_question.to_condition")
              }}</label>
            </div>
          </div>
          <div
            v-if="
              question?.to_condition ||
              question?.connection_to_type ||
              question.connection_to_id
            "
            class="col-md-6 mb-3 d-flex align-items-center"
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                          :disabled="!allow_edit"

                @change="removeIdToCondition(question)"
                v-model="question.has_to_id_conditions"
                type="checkbox"
                role="switch"
                :checked="question?.connection_to_id"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked">{{
                $t("marketing.add_question.has_group_conditions")
              }}</label>
            </div>
          </div>

          <div
            class="col-md-6 mb-3"
            v-if="question?.connection_to_type || question?.to_condition"
          >
            <label class="form-label">{{
              $t("marketing.add_question.connection_type")
            }}</label>
            <select
              @change="
                question.connection_to_condition == null;
                question.connection_to_index = null;
                changeQuestion(question, true);
              "
              v-if="
question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'select' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'check_box'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'number' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'text' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'multiple_choices'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'radio'
              
              "
                        :disabled="!allow_edit"

              v-model="question.connection_to_type"
              class="form-select"
            >
              <option value="greater_than">
                {{ $t("marketing.add_question.greater_than") }}
              </option>
              <option value="less_than">
                {{ $t("marketing.add_question.less_than") }}
              </option>
              <option value="values_in_list">
                {{ $t("marketing.add_question.values_in_list") }}
              </option>
            </select>
          </div>

          <div class="col-md-6 mb-3" v-if="question?.connection_to_type && !(question.has_to_id_conditions || question.connection_to_id)">
            <label class="form-label">{{
              $t("marketing.add_question.connection_index")
            }}</label>
            <select
              @change="changeQuestion(question)"
              v-if="
question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'select' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'check_box'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'number' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'text' ||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'multiple_choices'||
              question_types.find(
                (type) =>
                  type?.id == question?.marketing_survey_question_type_id
              )?.code == 'radio'
              "
                        :disabled="!allow_edit"

              v-model="question.connection_to_index"
              class="form-select"
            >
              <option
                v-for="(
                  connection, connection_index
                ) in group?.marketing_survey_questions.filter(
                  (inner_question) => inner_question.id != question?.id
                )"
                :key="connection_index"
                :value="connection_index"
                :disabled="
                  ((question?.connection_to_type == 'greater_than' ||
                    question?.connection_to_type == 'less_than') &&
                    connection.marketing_survey_question_type_id ==
                      question_types.find((type) => type.code == 'number')
                        .id) ||
                  (question?.connection_to_type == 'values_in_list' &&
                    connection.marketing_survey_question_type_id ==
                      question_types.find((type) => type.code == 'select').id)
                    ? false
                    : true
                "
              >
                {{ connection.question }}
              </option>
            </select>
          </div>
          <div class="col-md-6 mb-3" v-if="question?.connection_to_type && (question.has_to_id_conditions || question.connection_to_id) ">
          <label class="form-label">{{
            $t("marketing.add_question.connection_index")
          }}</label>
          <AutoComplete
          :completeOnFocus="true"
                            v-model="question.connection_to"
                            @item-select="question.connection_to_id =question.connection_to.id;changeQuestion(question,true)"
                            :suggestions="filteredQuestions.filter(q =>q?.id !=question?.id)"
                            @complete="searchQuestion($event.query,question?.connection_to_type)"
                                      :disabled="!allow_edit"


                            :optionLabel="showLabel"
                            style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
        </div>
          <div class="col-md-6 mb-3" v-if="question?.connection_to_index || question?.connection_to_id">
            <label class="form-label">{{
              $t("marketing.add_question.connection_condition")
            }}</label>
            <template
              v-if="
                question?.connection_to_type == 'greater_than' ||
                question?.connection_to_type == 'less_than'
              "
            >
              <input
                @input="changeQuestion(question)"
                v-model="question.connection_to_condition"
                          :disabled="!allow_edit"

                type="number"
                class="form-control"
              />
            </template>
                <template
            v-else-if="
              question?.connection_to_index ||
              (question?.connection_to_id &&
                findQuestionById(
                  marketing_survey_parts,
                  question?.connection_to_id
                )?.marketing_survey_question_type_id ==
                  question_types.find((qt) => qt.code == 'multiple_choices')
                    ?.id)
            "
          >
            <label
              v-for="option in question?.connection_to_index
                ? group?.marketing_survey_questions[question?.connection_to_index]
                    ?.marketing_survey_question_options
                : findQuestionById(
                    marketing_survey_parts,
                    question?.connection_to_id
                  )?.marketing_survey_question_options"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="checkbox"
                v-model="question.connection_to_condition"
                :value="option.id"
                @change="
                  changeQuestion(question, false, part_index, group_index)
                "
                class="input"
                          :disabled="!allow_edit"

              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option?.option }}
            </label>
          </template>
          <template
            v-else-if="
              question?.connection_to_id &&
              findQuestionById(marketing_survey_parts, question?.connection_to_id)
                ?.marketing_survey_question_type_id ==
                question_types.find((qt) => qt.code == 'check_box')?.id
            "
          >
            <label
              v-for="option in [1, 0]"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="radio"
                v-model="question.connection_to_condition"
                :value="option"
                @change="changeQuestion(question)"
                class="input"
                          :disabled="!allow_edit"

              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option ? $t("popups.yes") : $t("popups.no") }}
            </label>
          </template>
          <template
            v-else-if="
              question?.connection_to_id &&
              findQuestionById(marketing_survey_parts, question?.connection_to_id)
                ?.marketing_survey_question_type_id ==
                question_types.find((qt) => qt.code == 'radio')?.id
            "
          >
            <label
              v-for="option in findQuestionById(
                marketing_survey_parts,
                question?.connection_to_id
              )?.marketing_survey_question_options"
              :key="option?.id"
              class="d-flex align-items-center my-2"
              style="cursor: pointer; position: relative"
            >
              <input
                style=""
                type="radio"
                v-model="question.connection_to_condition"
                :value="option.id"
                @change="changeQuestion(question)"
                class="input"
                          :disabled="!allow_edit"

              />
              <span
                style="
                  position: relative;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: transparent;
                "
                class="box radio"
              ></span>
              {{ option?.option }}
            </label>
          </template>

          </div>
        </div>

        <div
          v-if="
            (question?.marketing_survey_question_type?.code == 'radio' ||
            question?.marketing_survey_question_type?.code ==
              'multiple_choices' ||
            question?.marketing_survey_question_type?.code == 'select') && (!group?.fixed_options || question_index<1 )
          "
          class="row mb-3"
        >
          <div class="col-2">
            <legend>{{ $t("marketing.add_question.question_options") }}</legend>
          </div>
          <div v-if="(!group?.fixed_options || question_index<1 )" class="col-4">
            <div class="d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-light float-end"
                @click="addOptions(question)"
                          :disabled="!allow_edit"

              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <template
          v-if="
            (question?.marketing_survey_question_type?.code == 'radio' ||
            question?.marketing_survey_question_type?.code ==
              'multiple_choices' ||
            question?.marketing_survey_question_type?.code == 'select') && (!group?.fixed_options || question_index<1 )
          "
        >
          <fieldset
            class="row mb-3"
            v-for="(
              option, index
            ) in question?.marketing_survey_question_options.filter(
              (option) => !option.is_other
            )"
            :key="index"
          >
            <div class="row justify-content-end mb-3">
              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn float-end"
                    @click="deleteOption(option)"
                    
                              :disabled="!allow_edit"

                  >
                    <span
                      class="bx bx-trash text-danger float-end fa-2x"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <label class="form-label">{{
                $t("marketing.add_question.question_option")
              }}</label>
              <input
                required
                @input="changeOption(option)"
                          :disabled="!allow_edit"

                v-model="option.option"
                type="text"
                class="form-control"
              />
            </div>
          </fieldset>
        </template>
      </fieldset>
    </fieldset>
  </fieldset>

  <!-- end  table -->

  <!--   Apps  Pagination     -->

  <!--end-->
</template>

<style scoped>
fieldset {
  border: 1px solid #ddd !important;
  margin: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding-left: 10px !important;
}
/* 	
		legend
		{
			font-size:14px;
			font-weight:bold;
			margin-bottom: 0px; 
			width: 35%; 
			border: 1px solid #ddd;
			border-radius: 4px; 
			padding: 5px 5px 5px 10px; 
			background-color: #ffffff;
		} */
</style>
