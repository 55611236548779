<script>
import appConfig from "@/app.config";
import { Modal } from "bootstrap";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      data: {
        marketing_area_ids:[],
        marketing_district_id: "",
      },
      marketingSurveyDistricts: [],
      archive_files_types: [],
      marketingAreas: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchArchiveFiles: null,
      ArchiveFilesSearchMode: false,
      searchModel: "",
      marketingDistricts: [],
      add_survey_area_modal: {},
      marketing_survey_areas_modal:{},
      marketingSurveyAreas:[],
      survey_area_data:{
        marketing_survey_areas:[],
        marketing_district_id:''
      },
      user:{}
    };
  },
  methods: {
 
    addArea() {
      console.log("step 35:",this.data);
      
      this.http
        .post(
          "marketing-survey-areas/add-multiple-areas",
          { ...this.data, marketing_survey_id: this.$route.params?.id },
          null,
          true
        )
        .then((res) => {
          if(res.status){
            this.add_survey_area_modal.hide()
            this.get(this.page);
          }
        });
    },
    search() {
      
      this.ArchiveFilesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post(
          "marketing-survey-areas/districts/search",
          {
            search: this.searchModel,
            limit: this.limit,
            page: this.page,
            col: "name",
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.marketingSurveyDistricts = res.data;
          console.log("######## files", this.marketingSurveyDistricts);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.ArchiveFilesSearchMode = false;
      this.get(this.page);
    },
    getMarketingAreas(id) {
      this.http.post("marketing-areas/pagination-by-district-id", {page :1,marketing_district_id:id}).then((res) => {
        this.marketingAreas = res.data;
      });
    },
   
  
    navigate(area) {
      console.log({ area });
      this.$router.push({
        name: "surveyArea",
        params: { surveyId: this.$route.params?.id, areaId: area?.marketing_area_id },
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post(
          "marketing-survey-areas/districts/pagination",
          {
            limit: this.limit,
            page: page,
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log({ returnedData: res.data });
          this.marketingSurveyDistricts = res.data;
        });
    },
    getMarketingDistricts() {
      this.http.get("marketing-districts", null, null, true).then((res) => {
        this.marketingDistricts = res.data;
      });
    },
    getMarketingSurveyAreasByDistrictId(id){
      return new Promise((resolve)=>{
        this.http.get('marketing-survey-areas/by-district-id',{marketing_district_id:id,marketing_survey_id: this.$route.params?.id}).then((res)=>{
          if(res.status)
          resolve(res.data)
        })
      })
    },
   async openMarketingAreasModal(district_id){

this.marketingSurveyAreas= await this.getMarketingSurveyAreasByDistrictId(district_id)

this.getMarketingAreas(district_id)
  console.log("step 145 :",this.marketingSurveyAreas);
  
  this.survey_area_data.marketing_survey_areas = this.marketingSurveyAreas?.map( area => area.marketing_area_id)
  this.survey_area_data.marketing_district_id = district_id
      this.marketing_survey_areas_modal.show()
    },
    changeMarketingSurveyArea(obj){
      console.log(obj);
      this.http.post('marketing-survey-areas/add-or-delete',{marketing_area_id:obj.marketing_area_id,marketing_survey_id: this.$route.params?.id,}).then((res)=>{
        if(res.status)
        this.get(this.page)
      })
    } 
  },
  created() {
          this.user=JSON.parse(localStorage?.user)

    this.get(1);
    this.getMarketingDistricts();
  },
  mounted() {
    this.add_survey_area_modal = new Modal("#addSurveyArea");
    this.marketing_survey_areas_modal = new Modal('#MarketingSurveyAreasModal')
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-8">
      <div
        class="search-box chat-search-box d-flex align-items-center"
        style="width: 250px; margin-inline-end: auto"
      >
        <div class="position-relative mx-3 d-flex align-items-center">
          <input
            @input="search()"
            type="text"
            class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            :placeholder="$t('users.searchplaceholder')"
            v-model="searchModel"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button
            @click="cancelappsearchMode()"
            v-if="searchModel"
            class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"
          ></button>
        </div>
        
      </div>
    </div>
    <div class="col-4">
      <button
          type="button"
          class="btn btn-light float-start"
          @click="
            marketingAreas:[];
            data = { marketing_area_ids: [], marketing_district_id: '' };
            add_survey_area_modal.show();
          "
                        :disabled="user.roles.some(role=> role?.role?.code =='marketing_survey_manager_no_edit')"

        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
    </div>

  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
         
          <th scope="col">
            {{ $t("marketing.team_member.interviewers.table.district") }}
          </th>

          
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in marketingSurveyDistricts" :key="app" class="text-center">
     
          <td>{{ app?.name }}</td>

         
          <td class="d-flex justify-content-start">
            
            <button
                  @click="openMarketingAreasModal(app.id)"
                  class="btn btn-primary btn-sm me-2"
                >
                   {{ $t("marketing.team_member.tabs.areas") }}
              </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Button trigger modal -->
  >

  <!-- Modal -->
  <div
    class="modal fade"
    id="addSurveyArea"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("popups.add") }}</h5>

        </div>
        <form @submit.prevent="addArea()">
          <div class="modal-body">
            <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.districts")
              }}</label>
              <select
                @change="getMarketingAreas(data.marketing_district_id)"
                v-model="data.marketing_district_id"
                class="form-select form-select"
                name=""
                id=""
              >
                <option
                  v-for="a in marketingDistricts"
                  :key="a.id"
                  :value="a.id"
                >
                  {{ a.name }}
                </option>
              </select>
            </div>
            <!-- <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.team_member.tabs.areas")
              }}</label>

              <select
                v-model="data.marketing_area_id"
                class="form-select mb-2"
                id="area"
              >
                <option
                  v-for="a in marketingAreas.filter(
                    (aa) =>
                      aa.marketing_district_id == data.marketing_district_id
                  )"
                  :key="a.id"
                  :value="a.id"
                >
                  {{ a.name }}
                </option>
              </select>
            </div> -->
            <div v-if="data.marketing_district_id" class="mb-3">
            
              <div
               v-for="a in marketingAreas"
                  :key="a.id"
                  :value="a.id"
               class="form-check">
                <input
                  :value="a.id"
                  class="form-check-input "
                  :class="{'float-end': $i18n.locale =='en'?false:true}"
                  type="checkbox"
                  v-model="data.marketing_area_ids"
                  
                />
                <label class="form-check-label  " :class="{'float-end': $i18n.locale =='en'?false:true,'me-4':$i18n.locale =='en'?false:true}" for="">  {{ a.name  }}  </label>
              </div>
              
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="add_survey_area_modal.hide()"
            >
              {{ $t("popups.close") }}
            </button>
            <button
              :disabled="
                !(data.marketing_district_id && data.marketing_area_ids.length)
              "
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="MarketingSurveyAreasModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("marketing.team_member.tabs.areas") }}</h5>
          
        </div>
        <!-- <form @submit.prevent="addArea()"> -->
          <div class="modal-body">
          
           
            <div  class="mb-3">
            
              <div
               v-for="a in marketingAreas"
                  :key="a.id"
                  :value="a.id"
               class="form-check">
                <input
                  :value="a.id"
                  class="form-check-input"
                  @change="changeMarketingSurveyArea({marketing_area_id:a.id})"
                  :disabled="user.roles.some(role=> role?.role?.code =='marketing_survey_manager_no_edit')"
                  :class="{'float-end': $i18n.locale =='en'?false:true}"
                  type="checkbox"
                  v-model="survey_area_data.marketing_survey_areas"
                  
                />
                <label class="form-check-label  " :class="{'float-end': $i18n.locale =='en'?false:true,'me-4':$i18n.locale =='en'?false:true}" for="">  {{ a.name  }}  </label>
              </div>
              
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="marketing_survey_areas_modal.hide()"
            >
              {{ $t("popups.close") }}
            </button>
           
          </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages > 0">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
